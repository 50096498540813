import React from "react"
import styled from "@emotion/styled"

const Form = styled.form`
  display: flex;
  flex-direction: column;
  > * {
    margin-bottom: 1rem;
    /* border: 1px solid #e9e9e9; */
    border-radius: 2px;
    padding: 0.8rem 1rem;
    font-size: 0.9rem;
    resize: none;
    background: #f8f9f6;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    border: none;
    color: #555;
  }
  button {
    cursor: pointer;
    width: 130px;
    display: flex;
    align-self: flex-end;
    justify-content: center;
    &:hover {
      background: #f8f9f696;
    }
  }
`

const ContactForm = () => {
  return (
    <Form
      name="contact"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      {/* You still need to add the hidden input with the form name to your JSX form */}
      <input type="hidden" name="form-name" value="contact" />
      <input required type="text" placeholder="Your e-mail" name="email" />
      <textarea
        required
        placeholder="Your message..."
        name="message"
        id="message-field"
        cols="30"
        rows="10"
      ></textarea>
      <button type="submit">Send</button>
    </Form>
  )
}

export default ContactForm
