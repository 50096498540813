import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import usePosts from "../hooks/use-posts"
import PostPreview from "../components/PostPreview"
import "../main.css"
import { css } from "@emotion/core"
import ContactForm from "../components/ContactForm"
import Experience from "../components/Experience"

const IndexPage = () => {
  const posts = usePosts()

  return (
    <Layout>
      <SEO title="Home" />
      <div className='presentation'>
        <p>I'm a Frontend Developer with 10 years of experience. I have worked for companies like Autodesk, Ticketmaster, VividCortex, Globant, and others. </p>
      </div>
      <Experience></Experience>
      <h3
        css={css`
          padding: 1rem 0;
          font-size: 1.5rem;
        `}
      >
        Get in touch
      </h3>
      <ContactForm></ContactForm>
      {/* {posts.map(post => {
        return <PostPreview key={post.slug} post={post}></PostPreview>
      })} */}
    </Layout>
  )
}

export default IndexPage
