import React from "react"
import styled from "@emotion/styled"

const parseSkills = skills => {
  return skills.split(',')
}

const ExperienceItem = ({ item }) => {
  let skills;
  if (item.skills) {
    skills = parseSkills(item.skills)
  }

  return (
    <Main>
      <div className="date">
        <div>{item.date}</div>
      </div>
      <div className="details">
        <h3>{item.name}</h3>
        <p>{item.text}</p>
        <br />
        {skills && skills.map(skill => {
          return <span key={Math.random()} className='tag'>{skill}</span>
        })}

      </div>
    </Main>
  )
}

const Main = styled.div`
    font-size: 15px;
    margin: 20px 0;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 1.5rem;
    border-bottom: 2px dashed antiquewhite;
    opacity: .7;
  .date {
    width: 25%;
    p {
      padding: 0;
      font-style: italic;
    }
  }
  .details {
    width: 70%;
  }
  /* background: red; */
  &:hover {
    opacity: 1;
  }
`

export default ExperienceItem
