import React from "react"
import ExperienceItem from "./Experience-Item"

const Items = [
  {
    name: "VividCortex",
    date: "Oct 2016 - Feb 2018",
    text: `VividCortex is a startup based on Charlottesville Virginia which strives to provide the best database monitoring systems for developers working on big databases. I was part of the frontend team developing the main application.`,
    skills: 'JavaScript, Angular, Less, Git, Jasmine'
  },
  {
    name: "Synchronit",
    date: "May 2016 - Sep 2016",
    text: `Worked as a remote contractor for a German compay which develops investment and other bank related applications`,
    skills: 'JavaScript, Angular, D3, CSS'
  },
  {
    name: "Globant",
    date: "Aug 2013 - Apr 2016",
    text: `Worked for companies like Autodesk and Tickemaster as part of the frontend team on different proyects.`,
    skills: 'JavaScript, Angular, React, Backbone, jQuery, Less, Jasmine, TDD, Git'
  },
  {
    name: "Intellicom",
    date: "May 2013 - Jul 2019",
    text: 'Small Digital agency. Worked mostly on small corporate sites and landing pages.',
    skills: `jQuery, PHP, jQuery Mobile, Sass`,
  },
  {
    name: "Suite Commerce",
    date: "May 2010 - May 2011",
    text: "Worked on SuiteCommerce, a company specialized in creating Netsuite based ecommerce sites. The company was later aquired by Netsuite. My role was a mix between design and development.",
    skills: `jQuery, CSS, Sass, Photoshop, Html`,
  },
]

const Experience = () => {
  return (
    <>
      <h2>Work Experience</h2>

      {Items.map(item => {
        return <ExperienceItem key={item.name} item={item}></ExperienceItem>
      })}
    </>
  )
}

export default Experience
